import * as React from "react";
import styled from "styled-components";
import Titlerich from "../Titlerich.js";
import Section from "./Section";

const Container = styled.div`
position: relative;

display: flex;
flex-direction: column;
align-content: space-around;
justify-content: center;
align-items: center;
width: 100%;



`

const Pstyled = styled.p`
  color: var(--gris);
  padding:20px 30px;
  text-align:center ;
  margin-bottom:48px ;
`;


const Metodologia = ({card}) => {
 
    return(
    <Container >   
       <Titlerich  color ={"var(--blanco"} colortext={"var(--negro)"} container={card.fields.tituloRich} colorbold={"var(--negro"}></Titlerich>
       <Pstyled >{card.fields.subtitulo}</Pstyled>
    
    { card.fields.proyectos.map((field , index)=>{
              return(
              <Section text ={field.fields.descripcion} image={field.fields.imagen.fields.file.url} titulo={field.fields.titulo} subtitulo={field.fields.subtitulo} color={index % 2 == 1 ? "var(--celeste)":"var(--verde)" } colorsoft={index % 2 == 1 ? "var(--celestergb)":"var(--verdergb)" }></Section>
              )
            })
          }
   
    </Container>
    )
    }
  
    export default Metodologia;