import * as React from "react";
import styled from "styled-components";
import Sideimage from "./Sideimage.js";
import Sideimageright from "./Sideimageright.js";

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 48px; ;
`;

const Trabajamos = ({ card }) => {
  
  return (
    <Container>
      {card.map((field, index) => {
        if (index % 2 == 0) {
          return (
            <Sideimage
              image={field.fields.imagen.fields.file.url}
              colorb={index == 0 ? "var(--magenta)" : "var(--verde)"}
            >
              <img
                src={field.fields.icono.fields.file.url}
                alt="trabajamos"
              ></img>
              <h3>{field.fields.titulo}</h3>
              <p>{field.fields.descripcion}</p>
            </Sideimage>
          );
        } else {
          return (
            <Sideimageright
              image={field.fields.imagen.fields.file.url}
              colorb={index == 1 ? "var(--naranja)" : "var(--morado)"}
            >
              <img
                src={field.fields.icono.fields.file.url}
                alt="image trabajamos section"
              ></img>
              <h3>{field.fields.titulo}</h3>
              <p>{field.fields.descripcion}</p>
            </Sideimageright>
          );
        }
      })}
    </Container>
  );
};

export default Trabajamos;
