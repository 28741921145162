import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 500px;
  justify-content: center;
  position: relative;
  margin: 0px 0px 24px 0px;

  @media (max-width: 900px) {
    height: auto;
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  position: relative;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Barcolo = styled.div`
  position: absolute;
  width: 100%;
  height: 374px;
  top: 63px;
  background-color: ${(p) => p.clr};
  z-index: 0;

  @media (max-width: 900px) {
    height: 100%;
  }
`;

const Image = styled.div`
  display: flex;
  width: 50%;
  padding: 2%;
  z-index: 1;
  img {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 30px;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 374px;
  top: 63px;
  padding: 2%;
  z-index: 2;
  color: var(--blanco);
  img {
    width: 64px;
    height: 64px;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  p {
    font-weight: 300;
    width: 80%;
  }

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    padding: 0px 30px;
    top: 30px;
  }
`;

const Sideimageright = ({ image, left, colorb, children }) => {
  return (
    <Container>
      <Subcontainer>
        <Image left={left}>
          <img src={image}></img>{" "}
        </Image>
        <Content left={left}>{children}</Content>
      </Subcontainer>
      <Barcolo clr={colorb}></Barcolo>
    </Container>
  );
};
export default Sideimageright;
