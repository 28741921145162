import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout.js";
import useContentful from "../../utils/useContentful";
import BoldContentful from "../../utils/BoldContentful";
import Titlerich from "../../components/Titlerich.js";
import Hero from "../../components/comotrabajamos/Hero.js";
import MetaTags from "react-meta-tags";
import Metodologia from "../../components/comotrabajamos/Metodologia.js";
import Trabajamos from "../../components/comotrabajamos/Trabajamos.js";
import "../../styles/global.css";
import { Helmet } from "react-helmet";
import Home from "../../images/Home/home.png";
import { Link } from "gatsby";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 60%;
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 36px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const TrabajamosEntry = "2tdYxoZxovFI15HNUHeNlr";

const IndexPage = ({ location }) => {
  const { data: trabajamos, fetched } = useContentful({ id: TrabajamosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

 
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{trabajamos.fields.pageTitle}</title>
            <meta
              name={trabajamos.fields.metaName}
              content={trabajamos.fields.metaContent}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/metodologias/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/metodologias/"
            />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/metodologias/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Cómo trabajamos",
                    item: "https://www.aporta.org.pe/metodologias/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <Hero
                imagen={trabajamos.fields.headerImagenDeFondo.fields.file.url}
              ></Hero>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/metodologias")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/metodologias"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Cómo trabajamos
                </Link>
              </Breadcrumbs>
              <Titlerich
                color={"var(--celeste"}
                colortext={"var(--negro)"}
                container={trabajamos.fields.tituloRich}
                colorbold={"var(--negro)"}
              ></Titlerich>
              <Pstyled>{trabajamos.fields.subtitulo}</Pstyled>
              <Trabajamos card={trabajamos.fields.capacidades}></Trabajamos>
              <Metodologia
                card={trabajamos.fields.metodologiasDeImpacto}
              ></Metodologia>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
